import { format } from 'date-fns'
import { humanReadableFileSize } from 'vuetify/lib/util/helpers'

const formatDate = (d, dateFormat = 'MM/dd/yyyy') => {
  return format(d, dateFormat)
}

const randomElement = (arr = []) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const kebab = (str) => {
  return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}

const bytes = (byte) => {
  return humanReadableFileSize(byte)
}

const toggleFullScreen = () => {
  let doc = window.document
  let docEl = doc.documentElement

  let requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
  let cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

// jsDateToISOLocalStr
// Converts a JS date to a "ISO-style" string (i.e. not converting to UTC Zero Offset Time)
// i.e. Suppose it is May 2, 2023 @ 4:36 AM, and we are on a system with a +10 timezone (around Australia)
// The difference between 'toISOString' builtin and jsDateToISOLocalStr is simply as thus:
// new Date().toISOString() --> '2023-05-01T18:36:31.866Z'
// jsDateToISOLocalStr()    --> '2023-05-02T04:36:31.866'
// All JS Date objects are stored as UTC, and so if a local timezone is displayed by JS, we must "convert back"
// params:
//   * d (optional) -- anything that can be parsed by JS new Date constructor
// returns -- One of the below:
//   * if d can be parsed by JS Date: an ISO-style string representation of d according to local (machine) time
//   * if d is not truthy or not passed in: an ISO-style string representation of 'right now' according to local time
//   * if d cannot be parsed by JS date (that is, when new Date(d) returns "Invalid Date"): null
export const jsDateToISOLocalStr = (d) => {
  let retVal = null;
  // We necessarily do some string parsing because JS Date doesn't parse dates consistently
  // e.g. -- new Date("2023-05-01") returns midnight at UTC,
  //         new Date("2023-05-01 ") (or any non-strict variant) returns midnight in the local timezone
  // We will force to the local time unless any time component is already in the string
  // If the total string length is less than 11, then there isn't any time component.
  const parsedInput = (typeof d === "string" || d instanceof String) && d.length < 11 ? `${d}` : d;
  // We must not pass a date argument if we want today's date. "new Date(null)" returns the epoch (Jan 1 1970)!
  const utcDate = d ? new Date(parsedInput) : new Date();
  if (String(utcDate).toLowerCase() !== "invalid date") {
      // Conversion to UTC already took place -- now we must *undo* that
      const localTimestamp = utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000;
      const localDate = new Date(localTimestamp);
      // Remove the final trailing 'Z' to indicate local time
      retVal = localDate.toISOString().slice(0, -1);
  }
  return retVal;
};

export default {
  randomElement,
  toggleFullScreen,
  formatDate,
  bytes,
  kebab,
}

import { createStore } from 'vuex'
//import Vue from 'vue'
//import auth from '@/store/modules/auth'

import modules from './modules'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  key: 'vma',
  storage: window.localStorage,
  modules: ['app', 'auth'],
})

//const getDefaultState = () => {
//  return {
//    
//  };
//};

export default createStore({
  //state: getDefaultState,
  //state:{},
  getters: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocal.plugin],
  modules
})

import authApi from '@/api/auth'
import {setItem} from '@/helpers/persistanceStorage'
//import { colorNames } from '@/util/colors'
//import * as colors from 'vuetify/util/colors'


// eslint-disable-next-line
/*
const randomColor = () => {
  //const temp = Object.keys(colors)
  const key = colorNames[Math.floor(Math.random() * colorNames.length)]
  const color = colors[key].base
  return color
}
*/
const state = {
  isSubmitting: false,
  isAuthenticated: null,
  user: {},
  validationErrors: null,
  access_token: null,
  expires_in: 3600,
  token_type: 'bearer',
  username: 'admin',
  avatar: null,
  userColor: '#2196f3',
  status: 'online',
  currentUser: null,
  getUserRole: null,

}

export const mutationTypes = {
//  registerStart: '[auth] Register start',
 // registerSuccess: '[auth] Register success',
 // registerFailure: '[auth] Register failure',

  loginStart: '[auth] Login start',
  loginSuccess: '[auth] Login success',
  loginFailure: '[auth] Login failure',
  getCurrentUserStart: '[auth] Get current user start',
  getCurrentUserSuccess: '[auth] Get current user success',
  getCurrentUserFailure: '[auth] Get current user failure',

  updateCurrentUserStart: '[auth] Update current user start',
  updateCurrentUserSuccess: '[auth] Update current user success',
  updateCurrentUserFailure: '[auth] Update current user failure',

  nullvalidationErrors: '[auth] Null validationErrors',

  logout: '[auth] logout'

}

export const actionTypes = {
 // register: '[auth] Register',
  login: '[auth] Login',
  getCurrentUser: '[auth] Get current user',
  updateCurrentUser: '[auth] Update current user',
  logout: '[auth] logout'

}
export const getterTypes = {
  currentUser: '[auth] currentUser',
  isAuthenticated: '[auth] isAuthenticated',
  isAnonymous: '[auth] isAnonymous',
  getUserRole: '[auth] getUserRole',
  isSubmitting: '[auth] isSubmitting',
  
}

const getters = {
  [getterTypes.isSubmitting]: state => {
    return state.isSubmitting },

  [getterTypes.currentUser]: state => {
  console.log('currentuserauth:', state.user)
    return state.user
  },
  [getterTypes.isAuthenticated]: state => {
    return !!state.token
  },
  [getterTypes.isAnonymous]: state => {
    return state.isAuthenticated === false
  },
  getAccessTokenN: (state) => {
    return state.token
  },
  [getterTypes.getUserRole]: (state) =>{
    return state.user.role
  },
  getAvatar: (state) => state.avatar,

  getUsername: (state) => state.username
  
}

// isAuthenticated: (state) =>
const mutations = {
 /* [mutationTypes.registerStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.registerSuccess](state, payload) {
    state.isSubmitting = false
    state.isAuthenticated = true
    state.user = payload
  },
  [mutationTypes.registerFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  }, */
  [mutationTypes.loginStart](state) {
    state.isSubmitting = true
    state.validationErrors = null
  },
  [mutationTypes.loginSuccess](state, payload) {
    state.isSubmitting = false
    state.isAuthenticated = true
    state.user = payload
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isSubmitting = false
    state.validationErrors = payload
  },
  [mutationTypes.getCurrentUserStart](state) {
    state.isLoading = true
  },
  [mutationTypes.getCurrentUserSuccess](state, payload) {
    state.isLoading = false
    state.isAuthenticated = true
    state.user = payload
    state.username = payload.name
  },
  [mutationTypes.getCurrentUserFailure](state) {
    state.isLoading = false
    state.isAuthenticated = false
    state.user = {}
  },
  [mutationTypes.updateCurrentUserStart]() {},
  [mutationTypes.updateCurrentUserSuccess](state, payload) {
    state.user = payload
  },
  [mutationTypes.updateCurrentUserFailure]() {},
  [mutationTypes.logout]() {
    state.user = {}
    state.isAuthenticated = false
  },
  [mutationTypes.nullvalidationErrors](state) {
    state.validationErrors = null
   }

}

const actions = {
 /* [actionTypes.register](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registerStart)
      authApi
        .register({
          name:credentials.nameUser,
          login:credentials.login,
          pass:credentials.password,
          email:'',
          role:credentials.role,
          active:credentials.active,
        }
          )
        .then(response => {
          context.commit(mutationTypes.registerSuccess,{} )
          
          resolve(response.data)
        })
        .catch(result => {
          context.commit(
            mutationTypes.registerFailure,
            {Error:[result.response.data.message]}
          )
       //  console.log('responce auth.js register result:', result.response.data.message)
        })
    })
  }, */
  [actionTypes.login](context, credentials) {
   // console.log('actionTypes.login')
    return new Promise(resolve => {
      context.commit(mutationTypes.loginStart)
      authApi
        .login(credentials)
        .then(response => {
          context.commit(mutationTypes.loginSuccess, response.data.user)
          console.log("response data auth", response.data)
          setItem('token', response.data.access_token)
          context.dispatch(actionTypes.getCurrentUser)
          resolve(response.data)
        })
        .catch(result => {
          context.commit(
            mutationTypes.loginFailure,
            {Error:[result.response.data.message]}
          )
        //  console.log('responce auth.js result:', result.response.data.message)
        })
    })
  },
  [actionTypes.getCurrentUser](context) {
   // console.log('actionTypes.getCurrentUser')
    return new Promise(resolve => {
      context.commit(mutationTypes.getCurrentUserStart)
      authApi
        .me()
        .then(response => {
         // console.log('respponse data user me 1:', response.data.user)
          context.commit(mutationTypes.getCurrentUserSuccess, response.data.user)
         // console.log('state.user.auth.js',state.user)
          setItem('user',response.data.user)
          console.log('respponse data user me :', response.data.user)
          setItem('username',response.data.user.name)
          console.log('respponse data user me 2:', response.data.user.name)
          //setItem('userColor', randomColor())
          resolve(response.data.user)
          
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setItem('user', {})
          setItem('token', '')
          context.commit(mutationTypes.getCurrentUserFailure)
          context.commit(mutationTypes.logout)
          }
        })
    })
  },
  [actionTypes.updateCurrentUser](context, {currentUserInput}) {
    return new Promise(resolve => {
      context.commit(mutationTypes.updateCurrentUserStart)
      authApi
        .updateCurrentUser(currentUserInput)
        .then(user => {
          context.commit(mutationTypes.updateCurrentUserSuccess, user)
          resolve(user)
        })
        .catch(result => {
          context.commit(
            mutationTypes.updateCurrentUserFailure,
            result.response.data.errors
          )
        })
    })
  },
  [actionTypes.logout](context) {
    return new Promise(resolve => {
      setItem('token', '')
      context.commit(mutationTypes.logout)
      resolve()
    })
  }

}

export default {
  state,
  actions,
  mutations,
  getters
}

<template>
   <v-app dark>
      <router-view></router-view>
      <!-- global snackbar -->
      <v-snackbar v-model="snackbar.show" :timeout="3000" app top centered :color="snackbar.color">
      {{ snackbar.text }}
         <template #action="{ attrs }">
            <v-btn icon v-bind="attrs" @click="$store.commit('HIDE_SNACKBAR')">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </template>
      </v-snackbar>
   </v-app>
</template>

<script>
//import ThemeSettings from '@/components/ThemeSettings'
//import OnlineUser from '@/components/OnlineUser'
import { mapGetters } from 'vuex'
import {useI18n} from 'vue-i18n'
export default {
   setup() {
      const {t} = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning
      return {t} // return render context that included `t`
   },
   components: {
      //ThemeSettings,
      //OnlineUser,
   },
   data() {
      return {
         rightDrawer: false,
         showSetting: true,
      }
   },
   computed: {
      ...mapGetters(['getSnackbar']),
      snackbar: {
        get() {
          return this.getSnackbar
        },
        set(val) {
          this.$store.commit('UPDATE_SNACKBAR', val)
        },
      },
   },
   mounted() {
      if (typeof window !== undefined && window._VMA === undefined) {
         window._VMA = this
      }
   },
   created() {},
   methods: {
      openThemeSettings() {
         this.$vuetify.goTo(0)
         this.showSetting = true
         this.rightDrawer = !this.rightDrawer
      },
      openOnlineUser() {
         this.$vuetify.goTo(0)
         this.showSetting = false
         this.rightDrawer = !this.rightDrawer
      },
   },
}
</script>

<style lang="sass" scoped>
.setting-fab
  top: 50% !important
  right: 0
  border-radius: 0
.chat-fab
  top: calc(50% + 40px) !important
  right: 0
  border-radius: 0

.orders :deep(th) 
   font-size: 1.0rem !important
 
.v-table :deep(th) 
   font-size: 1.0rem !important
.v-table:deep(td)
   font-size: 22px !important 
 
</style>

//import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import {getItem} from '@/helpers/persistanceStorage'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const routes = publicRoute.concat(protectedRoute)
//import store from '@/store'

const router = createRouter({
  history: createWebHistory(),
  routes
})


// router gards
router.beforeEach((to, from, next) => {
  NProgress.start()
  //const token = store.getters.getAccessToken
  const token = getItem('token')
  
  //console.log("router:",to)
  if (to.name !== 'login') {
    //console.log('token',token)
    if (token || to.meta.public) {
      next()
    } else {
      next({ name: 'login', query: { redirect: to.path } })
    }
  } else {
    next()
  }
  //auth route is authenticated
})

router.afterEach(() => {
  NProgress.done()
})

export default router

<template>
   <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon @click="handleDrawerToggle" />
      <v-app-bar-title> {{ getPageTitle() }}</v-app-bar-title>
      <v-spacer />

      <!-- v-btn text href="mailto:wangqiangshen@gmail.com">Hire Me</v-btn>
         <v-btn text href="https://www.isocked.com" target="_blank">Blog</v-btn>
         <v-btn text href="http://doc.isocked.com/">Doc</v-btn -->

      <v-btn icon @click="handleFullScreen()">
         <v-icon>mdi-fullscreen</v-icon>
      </v-btn>

      <v-menu offset-y origin="center center" class="elelvation-1" transition="scale-transition">
         <template v-slot:activator="{on}">
            <v-btn icon text v-bind="on">
               <v-badge v-if="getNotification.length > 0" color="red" overlap content="0">
                  <v-icon medium>mdi-bell</v-icon>
               </v-badge>
            </v-btn>
         </template>
         <notification-list v-show="getNotification.length > 0" :items="getNotification" />
      </v-menu>
      <!--  -->
      <!-- locale 
         <LocaleSwitch />-->
      <v-divider vertical class="mx-4"></v-divider>
      <v-menu transition="slide-y-transition">
         <template v-slot:activator="{props}">
            <v-btn icon large v-bind="props">
               <c-avatar :size="36" :username="getUsername" :src="getAvatar" status="online" />
            </v-btn>
         </template>
         <v-list class="pa-0">
            <v-list-item
               v-for="(item, index) in profileMenus"
               :key="index"
               :value="index"
               :to="!item.href ? {name: item.name} : null"
               :disabled="item.disabled"
               rel="noopener"
               rounded="shaped"
               color="primary"
               @click="item.click">
               <template v-slot:prepend v-if="item.icon"
                  ><v-icon>{{ item.icon }}</v-icon></template
               >
               <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
         </v-list>
      </v-menu>

      <!--
<v-list class="pa-0">
            <v-list-item
               v-for="(item, index) in profileMenus"
               :key="index"
               :value="index"
               :to="!item.href ? {name: item.name} : null"
               :href="item.href"
               :disabled="item.disabled"
               :target="item.target"
               rel="noopener"
               rounded="shaped"
               color="primary"
               @click="item.click">
               <template v-slot:prepend v-if="item.icon"
                  ><v-icon>{{ item.icon }}</v-icon></template
               >
               <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
         </v-list>

-->

      <!--  -- >
      <template v-slot:extension>
      <v-toolbar color="white" light  tag="div" class="w-100" height="48">
         <v-icon small>mdi-home</v-icon>
         <v-breadcrumbs :items="breadcrumbs" class="pa-3" dense />
         <v-spacer></v-spacer><v-spacer></v-spacer>
         <v-btn icon small color="black">
            <v-icon @click="handleGoBack" small>mdi-arrow-left</v-icon>
         </v-btn>
      </v-toolbar>
      </template>
      < !--  -->
   </v-app-bar>
</template>
<script>
import NotificationList from '@/components/list/NotificationList'
//import LocaleSwitch from '@/components/locale/LocaleSwitch'
import CAvatar from '@/components/avatar/CAvatar'
import {actionTypes} from '@/store/modules/auth'
import Util from '@/util'
import {mapGetters} from 'vuex'

export default {
   name: 'AppToolbar',
   components: {
      // LocaleSwitch,
      NotificationList,
      CAvatar,
   },
   props: {
      extended: Boolean,
   },
   data() {
      return {
         profileMenus: [
            {
               icon: 'mdi-account',
               href: '#',
               title: 'Profile',
               click: this.handleProfile,
               //  disabled: false,
            },
            {
               icon: 'mdi-cog',
               href: '#',
               title: 'Settings',
               click: this.handleSetting,
               name: 'app_settings',
               //  disabled: false,
            },
            {
               icon: 'mdi-power',
               href: '#',
               title: 'Logout',
               click: this.handleLogut,
            },
         ],
      }
   },
   computed: {
      ...mapGetters(['getAvatar', 'getUsername', 'getNotification']),
      breadcrumbs() {
         const {matched} = this.$route
         return matched.map((route, index) => {
            const to =
               index === matched.length - 1 ? this.$route.path : route.path || route.redirect
            const text = this.$t(route.meta.title)
            return {
               text: text,
               to: to,
               exact: true,
               disabled: false,
            }
         })
      },
   },
   created() {},
   methods: {
      getPageTitle() {
         return ''
      },
      handleDrawerToggle() {
         this.$emit('side-icon-click')
      },
      handleFullScreen() {
         Util.toggleFullScreen()
      },
      handleLogut() {
         console.log('routepush1:', this.$router)
         if (window.confirm('Are you sure to logout?')) {
            this.$store.dispatch(actionTypes.logout)
            window._VMA.$emit('SHOW_SNACKBAR', {
               text: 'Logout successfull',
               color: 'success',
            })
            this.$router.push('/auth/login')
         }
      },

      handleSetting() {
         console.log('routepush:', this.$router)
         // this.$router.push('/settings/AppSettings')
         this.$router.push({name: 'app_settings'})
         console.log('routepush:', this.$router)
      },
      handleProfile() {
         this.$router.push({name: 'userprofile'})
      },
      handleGoBack() {
         this.$router.go(-1)
      },
      handleProductionScreen() {
         this.$router.push({name: 'Production Screen'})
      },
   },
}
</script>

import {httpClient as axios} from '@/api/axios'
import authHeader from '../helpers/authHeader';

const register = credentials => {
  return axios.post('/users', credentials, { headers: authHeader() })
}

const login = credentials => {
  return axios.post('/auth/login', credentials)
}

const me = () => {
  return axios.get('/me', { headers: authHeader() })
}

const user = userLogin => {
  return axios.get('/users/'+userLogin, { headers: authHeader() })
}
const updateCurrentUser = currentUserInput => {
  return axios.put('/users/'+currentUserInput.login, currentUserInput , { headers: authHeader() }).then(response => response.data.user)
}
const changePassUser = UserInput => {
  return axios.put('/users/'+UserInput.login + '/passwd', UserInput , { headers: authHeader() }).then(response => response.data)
}

const userList = () => {
  return axios.get('/users', { headers: authHeader() })
}

const updateUserById = currentUserInput => {
  return axios.put('/users/', currentUserInput , { headers: authHeader() }).then(response => response.data.user)
}

const deleteUserById = userLogin => {
  return axios.delete('/users/'+userLogin, { headers: authHeader() })
}


export default {
  register,
  login,
  me,
  user,
  updateCurrentUser,
  userList,
  updateUserById,
  deleteUserById,
  changePassUser,
}
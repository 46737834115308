import {httpClient as axios}  from '@/api/axios'
import authHeader from '../helpers/authHeader'


const createOrder = orderInput => {
    return axios
        .post('/orders',  orderInput , { headers: authHeader() })
        .then(response => response)
}

const uploadFile = uploadFile => {
  return axios
      .post('/orders/import',  uploadFile , { headers: authHeader() })
      .then(response => response)
}

const getOrder = orderId => {
   return axios.get('/orders/'+orderId, { headers: authHeader() })
 }

 const getOrderItems = orderId =>{
  return axios.get('/orders/'+orderId+'/items', {headers: authHeader() })
 }
 
 const orderList = () => {
  return axios.get('/orders', { headers: authHeader() })
}


const updateOrder = currentOrder=> {
  return axios.put('/orders/'+currentOrder.id, currentOrder , 
  { headers: authHeader() }).then(response => response.data)
}
const saveItems = (orderId, payload)=> {
  return axios.put('/orders/'+orderId+'/items', {orderid:parseInt(orderId), items: payload }, 
  { headers: authHeader() }).then(response => response.data)
}

const deleteOrderById = orderId => {
  return axios.delete('/orders/'+orderId, { headers: authHeader() })
}

export default {
    getOrder,
    updateOrder,
    createOrder,
    orderList,
    deleteOrderById,
    getOrderItems,
    saveItems,
    uploadFile,
  
}
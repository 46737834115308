import { mutationTypes as authMutationTypes } from "@/store/modules/auth"
const state = {
    isSubmitting: false,
    validationErrors: null,
    padding: 1,
    interval: 1
}
export const mutationTypes = {
    changePadding: '[padding] Change start',
    changeInterval: '[interval] Change start',
  }
const mutations = {
    [authMutationTypes.updateCurrentUserStart](state) {
        state.isSubmitting = true,
            state.validationErrors = null
    },
    [authMutationTypes.updateCurrentUserSuccess](state) {
        state.isSubmitting = false
    },
    [authMutationTypes.updateCurrentUserFailure](state, payload) {
        state.isSubmitting = false
        state.validationErrors = payload
    },
    [mutationTypes.changePadding](state, payload){
        state.padding = payload.padding
        state.interval = payload.interval
    }
}


const getters = {
    getPadding: (state) => {
      return state.padding
  
    },
getInterval: (state) => {
    return state.interval
}
  }
  
  export const actionTypes = {
    changePaddingInterval: '[paddingInterval] change padding',

  }

  // actions
  const actions = {
    [actionTypes.changePaddingInterval](context,  paddingInput ) {
        console.log('action padd:', paddingInput)
        return new Promise(resolve => {
            context.commit(mutationTypes.changePadding,
                { padding: paddingInput.padding,
                    interval: paddingInput.interval
                })
            resolve(true)
        })
    }
  }
  

export default {
    state,
    getters,
    actions,
    mutations
}
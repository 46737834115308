<template>
  <div class="admin">
    <app-drawer ref="drawer" class="admin_drawer" @drawer:collapsed="mini = !mini" />
    <app-toolbar class="admin_toolbar" extended @side-icon-click="handleDrawerVisiable" />
    <v-main class="grey lighten-3">
      <!-- Page Wrapper -->
      <div class="page_wrapper"><router-view /></div>
      <!-- App Footer -->
      <v-footer height="auto" class="pa-3 app--footer">
        <span>LazyCoders design &copy; {{ new Date().getFullYear() }}</span>
        <v-spacer />
        <span class="caption mr-1">Powered by </span>
        <a href="https://hqainc.us/" target="_blank">
          <svg x="0px" y="5px" viewBox="0 0 400 178.2" style="height: 21px; width: 52px">
            <g id="bg"><rect id="Rectangle-path" y="0" class="st00" width="400" height="178.2" /></g>
            <g id="txt">
              <g>
                <polygon id="Shape" class="st1" points="124.8,146.5 97.4,146.5 97.4,65.9 53.1,65.9 53.1,146.5 25.7,146.5 25.7,0 53.1,0 53.1,38.7 124.8,38.7    " />
                <path class="st1" d="M247.5,39.6v138.6h-26.9v-44.8c-7,7.4-17.4,11.9-29.8,11.9c-32.5,0-46.3-22.8-46.3-53s13.9-52.7,46.3-52.7 c12.4,0,22.7,4.5,29.8,11.7V39.6L247.5,39.6z M220.6,92.3c0-20.9-7.4-26.1-24.6-26.1s-24.6,5.1-24.6,26.1 c0,21.2,7.4,26.3,24.6,26.3S220.6,113.5,220.6,92.3L220.6,92.3z" />
                <path class="st1" d="M374.3,119.5v27h-40.7v-10.4c-5.2,5.4-13.3,10.4-29,10.4c-25.4,0-37.3-16.2-37.3-35.3s11.9-35.3,37.3-35.3 c15.6,0,23.8,4.8,29,10.4V66.6h-54.2v-27h81.3v79.9H374.3z M333.6,111.2c0-8.3-5.4-10.8-19.6-10.8s-19.6,2.5-19.6,10.8 S299.8,122,314,122C328.2,122,333.6,119.5,333.6,111.2z" />
              </g>
            </g>
        </svg>
      </a>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <!-- <app-fab /> -->
  </div>
</template>
<style type="text/css">
.st00 {
  fill: #d22027;
}
.st1 {
  fill: #ffffff;
}
</style>
<script>
import AppDrawer from '@/components/AppDrawer'
import AppToolbar from '@/components/AppToolbar'
//import AppFab from '@/components/AppFab'

export default {
  name: 'LayoutDefault',
  components: {
    AppDrawer,
    AppToolbar,
    //AppFab, 
  },

  data() {
    return {
      mini: false,
      showDrawer: true,
    }
  },
  methods: {
    handleDrawerVisiable() {
      this.$refs.drawer.toggleDrawer()
    },
  },
}
</script>

<style lang="sass" scoped>
.page_wrapper
  min-height: calc(100vh - 112px)
  padding-top: 3px
  .container
    max-width: 1200px
</style>

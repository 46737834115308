<template>
  <v-list-item :to="item.path" :prepend-icon="item.icon">
  <!-- v-tooltip bottom >
        <template #activator="{ on, attrs }" >
          <v-icon v-bind="attrs" v-on:click="on" >item.icon</v-icon>
        </template>
        <span v-text="item.title" />
      </v-tooltip -->
      <v-list-item-title v-text="item.title" />
    <v-list-item-action v-if="item.isNew">
      <v-icon color="green">mdi-new-box</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  computed: {},
}
</script>

//import orderApi from '@/api/order'

const state = {
   
    isLoading: false,
    device:[],
    isSubmitting: false,
    validationErrors: null
}
export const getterTypes = {
  getDeviceSettings: '[device] get Device Settings'
}

const getters = {
    [getterTypes.getDeviceSettings]: (state) => {      
      return state.device.map((item) => {
        return {
        deviceType: item.deviceType,
         deviceName: item.deviceName,
         address: item.address,
         port: item.port,
         timeOut: item.timeOut,
         pollInterval: item.pollInterval,
         debugInfo: item.debugInfo,
         etherNetDevice: item.etherNetDevice        
        }
      })
    },
  }
export const mutationTypes = {
    createDeviceSettingStart: '[device] Create device start',
    createDeviceSettingsSuccess: '[device] Create device success',
    createDeviceSettingsFailure: '[device] Create device failure',

    updateDeviceSettingStart: '[device] Update device start',
    updateDeviceSettingSuccess: '[device] Update device success',
    updateDeviceSettingFailure: '[device] Update device failure',

    
}

export const actionTypes = {
    getDeviceSettings: '[device] Get device settings',
    
    updateDeviceSettings: '[device] Update device settings',
    
}

const mutations = {
    [mutationTypes.createOrderStart](state) {
        state.isSubmitting = true
        
    },
    [mutationTypes.createOrderSuccess](state, payload) {
        state.isSubmitting = false
        state.data.unshift(payload)
        
    },
    [mutationTypes.createOrderFailure](state, payload) {
        state.isSubmitting = false
            state.validationErrors = payload
            
    },
    [mutationTypes.getOrderByIdStart](state) {
        state.isLoading = true
        state.data = null
    },
    [mutationTypes.getOrderByIdSuccess](state, payload) {
        state.isLoading = false
        state.data = payload
    },
    [mutationTypes.getOrderByIdrSuccess](state) {
        state.isLoading = false
    },
   

    [mutationTypes.updateOrderStart](){},
    [mutationTypes.updateOrderSuccess](state, payload){
        state.data = payload
        console.log("updating state")
        const index = state.orders.map(function(e) {
          return e.id;
        }).indexOf(payload.id);
        state.orders[index] = payload;
    },
    [mutationTypes.updateOrderFailure](){},


}



/* const actions = {
   
    [actionTypes.updateDeviceSettings](context, {currentOrder}) {
        return new Promise(resolve => {
           console.log('action update order: ', context)
          context.commit(mutationTypes.updateOrderStart)
          orderApi
            .updateOrder(currentOrder)
            .then(order => {
              context.commit(mutationTypes.updateOrderSuccess, order)
              resolve(order)

            })
            .catch(result => {
              console.log("update failed:", result)
              context.commit(
                mutationTypes.updateOrderFailure,
                result.data.errors
              )
            })
        })
      }, 
      
    
  
} */

export default {
    state,
  //  actions,
    mutations,
    getters
}

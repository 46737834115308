import axios from 'axios'
import store from '@/store/modules/app'
//import authHeader from '../helpers/authHeader';

//const baseURL = 'http://127.0.0.1:8081/v1'
const baseURL = 'https://marcstone.hqainc.us/v1'


axios.defaults.baseURL = baseURL

/*
.catch(function (error) {
     console.log(error.response.status) // 401
     console.log(error.response.data.error) //Please Authenticate or whatever returned from server
   if(error.response.status==401){
     //redirect to login
   }

*/

const httpClient = axios.create({
    baseURL: baseURL,
    headers: {
      //Authorization: 'Bearer {token}',
      //timeout: 1000, // indicates, 1000ms ie. 1 second
      "Content-Type": "application/json",
  
    }
  })

  //const authInterceptor = (config) => {
  //  config.headers['Authorization'] = authHeader();
  //  console.log("auth header", authHeader())
  //  return config;
  //}

  const responseInterceptor = response => {
    return response
  }

  const errorInterceptor = error => {
  
  
  // check if it's a server error
    if (!error.response) {
      //notify.warn('Network/Server error');
      console.error('**Network/Server error');
      console.log(error.response);
      return Promise.reject(error);
    }
  
    // all the other error responses
    switch (error.response.status) {
      case 400:
        console.error('**Network/Server error 400');
        console.error(error.response.status, error.message);
        return Promise.reject(error);
        //notify.warn('Nothing to display', 'Data Not Found');
       // break;
  
      case 401: // authentication error, logout the user
        //notify.warn('Please login again', 'Session Expired');
        console.error(error.response.status, error.message);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location = '/auth/login';
        //router.push('/login');
        break;
        
    case 422:
      store.commit('SHOW_SNACKBAR', { text: error.message, color: 'error' })
      break
    
    case 403:
      store.commit('SHOW_SNACKBAR', { text: error.message, color: 'error' })
      break
    case 500:
      store.commit('SHOW_SNACKBAR', { text: 'server error', color: 'error' })
      break
      case 501:
        this.$store.commit('SHOW_SNACKBAR', { text: 'Not implemented', color: 'error' })
        console.error(error.response.status, error.message);
     // store.commit('SHOW_SNACKBAR', { text: 'Not implemented', color: 'error' })
      break
  
  default:
    console.error(error.response.status, error.message);
    //notify.error('Server Error');
  
  
  
   }
    return Promise.reject(error);
  }
  //httpClient.interceptors.request.use(authInterceptor);
  httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);
  
export  {
    axios,
    httpClient
}
import { LayoutAuth, LayoutDefault } from '@/components/layouts'

export const publicRoute = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/live',
    name: 'production-live',
    meta: {
      title: 'production',
      icon: 'mdi-factory',
      public: true,
    },
    component: () => import('@/views/public/Live.vue'),
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: {
      title: 'Login',
      public: true,

    },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: {
          title: 'Login',
          public: true,

        },
        component: () => import('@/views/auth/Login.vue'),
      },
    ],
  },
  
  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Not Found',
      public: true,

    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'Server Error',
      public: true,

    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'home',
      icon: '',
    },
    redirect: '/production',
    children: [
       
      {
        path: '/production',
        name: 'production',
        meta: {
          title: 'production',
          icon: 'mdi-factory',
        },
        component: () => import('@/views/ScreenProduction.vue'),
      },
      {
        path: '/orders',
        meta: {
          title: 'orders',
          icon: 'mdi-file-document-multiple-outline',
        },
        name: 'orders',
        component: () => import('@/views/orders/OrdersList.vue'),
      },
    
      {
        path: '/orders/:orderid',
        meta: {
          title: 'order',
          icon: 'mdi-file-document-multiple-outline',
          hidden: true,
        },
        name: 'order',
        component: () => import('@/views/orders/Order.vue'),
      },
      {
        path: '/orders/:orderid/production',
        meta: {
          title: 'order',
          icon: 'mdi-file-document-multiple-outline',
          hidden: true,
        },
        name: 'order-production',
        component: () => import('@/views/orders/OrderProduction.vue'),
      },
      {
        path: '/userslist',
        meta: {
          title: 'userslist',
          icon: 'mdi-account-group-outline',
          hidden: true,
        },
        name: 'userslist',
        component: () => import('@/views/UsersList.vue'),
      },
      {
        path: '/orders/:orderid/items',
        meta: {
          title: 'userslist',
          icon: 'mdi-account-group-outline',         
          hidden: true,

        },
        name: 'orderitems',
        component: () => import('@/views/orders/NewItem.vue'),
      },

      {
        path: '/settings',
        meta: {
          title: 'app_settings',
          icon: 'mdi-cogs',
        },
        name: 'app_settings',
        component: () => import('@/views/settings/AppSettings.vue'),
      },
      {
        path: '/settings',
        meta: {
          title: 'Production Screen',
          icon: 'mdi:power-socket-de',
          hidden: true,
        },
        name: 'Production Screen',
        component: () => import('@/views/ScreenProduction.vue'),
      },
      
      {
        path: '/orders/:orderid/orderdetails',
        meta: {
          title: 'order_details',
          icon: 'mdi-cogs',
          hidden: true,
        },
        name: 'order_details',
        component: () => import('@/views/orders/OrderDetailView.vue'),
      }, 
      {
        path: '/userprofile',
        name: 'userprofile',
        meta: {
          title: 'userprofile',
          icon: 'mdi-card-account-details-outline',
          hidden: false,
        },
        
        component: () => import('@/views/Settings.vue'),
      },      
      {
        path: '/403',
        name: 'Forbidden',
        meta: {
          title: 'access_denied',
          hidden: true,
        },
        component: () => import('@/views/error/Deny.vue'),
      },
    ],
  },

]

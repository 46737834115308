import orderApi from '@/api/order'

const state = {
    data: [],
// data:null,
    isLoading: false,
    error: null,
    orders:[],
    isSubmitting: false,
    validationErrors: null
}
export const getterTypes = {
  getOrdersList: '[order] get Orders List'
}

const getters = {
    [getterTypes.getOrdersList]: (state) => {
     console.log('state.order getters:',state.data)
      console.log('state:',state)
      return state.orders.map((item) => {
        return {
            id: item.id,
            job: item.job,
            attention: item.attention,
            orderDate: item.orderDate,
            quantity: item.quantity,
            jobStatus: item.status,
            contractor: item.contractor,
            project: item.project,
            assignee: item.assignee

         
        }
      })
    },
  }
export const mutationTypes = {
    createOrderStart: '[order] Create order start',
    createOrderSuccess: '[order] Create order success',
    createOrderFailure: '[order] Create order failure',

    getOrderByIdStart: '[order] orderGet order start',
    getOrderByIdSuccess: '[order] Get order success',
    getOrderByIdFailure: '[order] Get order failure',

    deleteOrderStart: '[order] Delete order start',
    deleteOrderSuccess: '[order] Delete order success',
    deleteOrderFailure: '[order] Delete order failure',

    getOrderListStart: '[order] Get order list start',
    getOrderListSuccess: '[order] Get order list success',
    getOrderListFailure: '[order] Get order list failure',

    updateOrderStart: '[order] Update order start',
    updateOrderSuccess: '[order] Update order success',
    updateOrderFailure: '[order] Update order failure',

    uploadFileStart: '[order] Upload file start',
    uploadFileSuccess: '[order] Upload file success',
    uploadFileFailure: '[order] Upload file failure',

    nullvalidationErrors: '[order] Null validationErrors',

    
}

export const actionTypes = {
    getOrderById: '[order] Get order',
    deleteOrderById: '[order] Delete order',
    getOrderList: '[order] Get order list',
    updateOrder: '[order] Update order',
    createOrder: '[createArticle] Create order',
    uploadFile: '[order] Upload file',
    saveItems: '[items] Save items',
    loadItems: '[items] Load items',
}

const mutations = {
    [mutationTypes.createOrderStart](state) {
        state.isSubmitting = true
        
    },
    [mutationTypes.createOrderSuccess](state, payload) {
        state.isSubmitting = false
        state.orders.unshift(payload)
        console.log('state.orders:', state.orders)
        
    },
    [mutationTypes.createOrderFailure](state, payload) {
        state.isSubmitting = false
            state.validationErrors = payload
            
    },
    [mutationTypes.getOrderByIdStart](state) {
        state.isLoading = true
        state.data = null
    },
    [mutationTypes.getOrderByIdSuccess](state, payload) {
        state.isLoading = false
        state.data = payload
    },
    [mutationTypes.getOrderByIdrSuccess](state) {
        state.isLoading = false
    },
    [mutationTypes.deleteOrderStart]() { 
      state.isSubmitting = true
    },
    [mutationTypes.deleteOrderSuccess](state, id) { 
      state.isSubmitting = false
      const index = state.orders.findIndex(item => item.id == id);
      console.log("state index:", index)
      if (index != -1) {
        state.orders.splice(index, 1);
      } 
    },
    [mutationTypes.deleteOrderFailure]() {
      state.isSubmitting = false
     },

    [mutationTypes.getOrderListStart](){},
    [mutationTypes.getOrderListSuccess](state, payload){
        state.orders = payload
    },
    [mutationTypes.getOrderListFailure](){},

    [mutationTypes.updateOrderStart](){},
    [mutationTypes.updateOrderSuccess](state, payload){
        state.data = payload
        console.log("updating state")
        const index = state.orders.map(function(e) {
          return e.id;
        }).indexOf(payload.id);
        state.orders[index] = payload;
    },
    [mutationTypes.updateOrderFailure](){},

    [mutationTypes.nullvalidationErrors](state) {
      state.validationErrors = null
     },

     [mutationTypes.uploadFileStart](){},
     [mutationTypes.uploadFileSuccess](){},
     [mutationTypes.uploadFileFailure](state, payload) {
        state.isSubmitting = false
            state.validationErrors = payload
            
    },


}



const actions = {
    [actionTypes.createOrder](context,  orderInput ) {
         console.log('action create order: ', orderInput)
         return new Promise(resolve => {
             context.commit(mutationTypes.createOrderStart)

             orderApi.createOrder(orderInput).then(resp => {
                 context.commit(mutationTypes.createOrderSuccess, resp.data)
                 console.log('responce createorder.js', resp.data)
                 resolve(resp.data)
             })
                 .catch(result => {
                  console.log('responce createorder.js result', result)
                  console.log('responce createorder.js result2', result.response.data.message)
                 // Object.keys(result.response.data.message).map((name) => {
                   // const messages = result.response.data.message[name].join(', ')
                 // })
        
                     context.commit(mutationTypes.createOrderFailure,
                         result.response.data.message)
                      //   console.log('responce createorder.js result', result.response.data.message) 
                         
                 })
         })
     },
     [actionTypes.getOrderById](context, id) {
        return new Promise(resolve => {
          context.commit(mutationTypes.getOrderByIdStart) 
            orderApi
                .getOrder(id)
                .then(response => {
               //   context.commit(mutationTypes.loginSuccess, response.data.user)
                  console.log('response.data action getOrderById', response.data)
              /*    setItem('token', response.data.access_token)
                  context.dispatch(actionTypes.getCurrentUser) */
                  context.commit(mutationTypes.getOrderByIdSuccess, response.data) 
                  resolve(response.data)
                })
            
              })
    },
    [actionTypes.loadItems](context, id) {
        return new Promise(resolve => {
            orderApi
                .getOrderItems(id)
                .then(response => {
               //   context.commit(mutationTypes.loginSuccess, response.data.user)
              //    console.log('response.data', response.data)
                  resolve(response.data.items)
                })
              })
    },

    
    [actionTypes.getOrderList](context) {
          return new Promise(resolve => {
            context.commit(mutationTypes.getOrderListStart)
           orderApi.orderList()
          .then(response => {
            console.log('/order response:', response.data)
            context.commit(mutationTypes.getOrderListSuccess, response.data) 
         //   console.log('list', response.data)
            resolve(response)
          })
        })
    },
    [actionTypes.updateOrder](context, {currentOrder}) {
        return new Promise(resolve => {
           console.log('action update order: ', context)
          context.commit(mutationTypes.updateOrderStart)
          orderApi
            .updateOrder(currentOrder)
            .then(order => {
              context.commit(mutationTypes.updateOrderSuccess, order)
              resolve(order)

            })
            .catch(result => {
              console.log("update failed:", result)
              context.commit(
                mutationTypes.updateOrderFailure,
                result.data.errors
              )
            })
        })
      },
      [actionTypes.saveItems](context, {orderId, items}) {
        return new Promise(resolve => {
           console.log('items:', items)
          //context.commit(mutationTypes.updateOrderStart)
          orderApi
            .saveItems(orderId, items)
            .then(order => {
             //   console.log('order:', order)
              //context.commit(mutationTypes.updateOrderSuccess, order)
              resolve(order)
            })
            .catch(result => {
                console.log(result)
             // context.commit(
                //mutationTypes.updateOrderFailure,
                //result.response.data.errors
              //)
            })
        })
      },

      [actionTypes.deleteOrderById](contex, id) {
          
        return new Promise(resolve => {
      contex.commit(mutationTypes.deleteOrderStart)
        orderApi
              .deleteOrderById(id)
              .then(response => {
                contex.commit(mutationTypes.deleteOrderSuccess, id)
                console.log('response.data', response.data)
                resolve(response.data)
              })
          
            })
        },
    
 [actionTypes.uploadFile](contex, uploadFile) {
  return new Promise(resolve => {
    contex.commit(mutationTypes.uploadFileStart)
      orderApi
            .uploadFile(uploadFile)
            .then(response => {
            //  contex.commit(mutationTypes.uploadFileSuccess, id)
              console.log('response.data', response.data)
              resolve(response.data)
            })
            .catch(result => {
              console.log("upload file:", result)
              contex.commit(
                mutationTypes.uploadFileFailure,
                result.response.data.message
              )
            })
          })
 }
}

export default {
    state,
    actions,
    mutations,
    getters
}
